import { render, staticRenderFns } from "./index.vue?vue&type=template&id=104de28c&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=104de28c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "104de28c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiSharedDialogsDialogMFullPage: require('/app/components/ui/shared/dialogs/DialogMFullPage.vue').default})
