const controller = 'admin/dashboard'
export default axios => ({
  getDashboardStatistics(obj) {
    return axios.get(`${controller}/dashboard-statistics`, obj)
  },
  updateHolidayCard(obj) {
    return axios.post(`${controller}/add-holiday-greeting`, obj)
  },
  deleteImageFromGallery(obj) {
    return axios.delete(
      `${controller}/delete-holiday-greeting-gallery/${obj.id}    `,
      obj
    )
  },
  getHolidayGreetingCard(obj) {
    return axios.get(`${controller}/get-holiday-greeting`, obj)
  },
  getHolidayGreetingCardGallery(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}&category=${obj.category}`
    return axios.get(`${controller}/get-holiday-greeting-gallery${parmas}`, obj)
  },
  updateHolidayGreetingCardGalleryText(obj) {
    return axios.put(
      `${controller}/update-holiday-greeting-text?category=${obj.id}`,
      obj
    )
  },
  getProperdeeAlerts(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    return axios.get(`${controller}/get-Properdee-alerts${parmas}`, obj)
  },
  addProperdeeAlerts(obj) {
    return axios.post(`${controller}/add-Properdee-alerts`, obj)
  },
  deleteProperdeeAlerts(obj) {
    return axios.delete(`${controller}/delete-Properdee-alerts/${obj.id}`, obj)
  },
  updateProperdeeAlerts(obj) {
    return axios.put(`${controller}/update-Properdee-alerts/${obj.id}`, obj)
  },
  getAllUsers(obj) {
    let parmas = `?type=${obj.type}&itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    if (obj.subscriptionType) {
      parmas = parmas + `&subscriptionType=${obj.subscriptionType}`
    }
    return axios.get(`${controller}/get-all-users${parmas}`)
  },
  getAllUsersDetails(obj) {
    return axios.get(`${controller}/get-user-details/${obj.id}`)
  },
  addVideoToLib(obj) {
    return axios.post(`${controller}/add-video-library`, obj)
  },
  updateVideoToLib(obj) {
    return axios.put(`${controller}/update-video-library/${obj.id}`, obj)
  },
  deleteVideoToLib(obj) {
    return axios.delete(`${controller}/delete-video-library/${obj.id}`)
  },
  getVideoToLib(obj) {
    let parmas = `?categoryId=${obj.categoryId}&itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.get(`${controller}/get-video-library${parmas}`)
  },
  deleteUser(obj) {
    return axios.delete(`${controller}/delete-user/${obj.id}`)
  },
  getAllSubscriber(obj) {

    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`

    return axios.get(`${controller}/get-subscribers${parmas}`)
  },
  verifySubscriber(obj) {
    return axios.post(`${controller}/verify-subscribers`, obj)
  },
  usertoAdminChange(obj) {
    return axios.put(`${controller}/update-admin-role/${obj.id}`)
  },
  getAllInquiry(obj) {

    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`

    return axios.get(`${controller}/inquiry${parmas}`)
  },
  addEnquiry(obj) {
    return axios.post(`${controller}/inquiry`,obj)
  },
  deleteEnquiry(obj) {
    return axios.delete(`${controller}/inquiry/${obj.id}`,obj)
  },
  getPackageForDiscount(obj) {

    return axios.get(`${controller}/discount/pricing`)
  },
  getDiscountList(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.get(`${controller}/discount${parmas}
`)
  },
  addNewDiscountCode(obj) {

    return axios.post(`${controller}/discount`,obj)
  },
  deleteDiscountCode(obj) {

    return axios.delete(`${controller}/discount/${obj.id}`)
  },
  changeStautsDiscountCode(obj) {

    return axios.put(`${controller}/discount/${obj.id}?status=${obj.status}`)
  },
  getEmailOrPopupMaincat(obj) {
    
    return axios.get(`${controller}/emails-and-popups
`)
  },
  getEmailOrPopupSubCat(obj) {
    return axios.get(`${controller}/emails-and-popups/${obj.catId}/subcategories
`)
  },
  getEmailOrPopupSubCatList(obj) {
    return axios.get(`${controller}/emails-and-popups/${obj.catId}/subcategories/${obj.subCateId}
`)
  },
  
})
