const actions = {
  async getDocumentsList({ commit }, params) {
    const res = await this.$repositories.documents.getDocumentsList(params)

    return res.data
  },
  async deleteDocument({ commit }, params) {
    const res = await this.$repositories.documents.deleteDocument(params)

    return res.data
  },
  async createLeaseDocument({ commit }, params) {
    const res = await this.$repositories.documents.createLeaseDocument(params)

    return res.data.data
  },
  async editLeaseDocument({ commit }, params) {
    const res = await this.$repositories.documents.editLeaseDocument(params)

    return res.data.data
  },
  async getLeaseData({ commit }, params) {
    const res = await this.$repositories.documents.getLeaseData(params)

    return res.data
  },
  async createPurchaseLeaseDocument({ commit }, params) {
    const res = await this.$repositories.documents.createPurchaseLeaseDocument(params)

    return res.data
  },
}

export default actions
